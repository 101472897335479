// src/router/index.js

import ApiService from '@/services/api'
import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: { requiresAuth: true },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/AccessDenied.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardView.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'asesor', 'cliente'],
      permission: 'show-dashboard'
    }
  },
  {
    path: '/tipos',
    component: () => import('@/views/params/TypesView.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'asesor', 'cliente'],  
      permission: 'show-tipos-de-personas'
    }
  },
  {
    path: '/estados',
    component: () => import('@/views/params/EstadosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/paises',
    component: () => import('@/views/params/PaisesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/departamentos',
    component: () => import('@/views/params/DepartamentosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/ciudades',
    component: () => import('@/views/params/CiudadesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sectores',
    component: () => import('@/views/params/SectoresView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/categorias-inventarios',
    component: () => import('@/views/params/CategoriasInventariosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/items-inventarios',
    component: () => import('@/views/params/ItemsInventariosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/categorias-inventarios/:id/items',
    component: () => import('@/views/params/ItemsCategoriasView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/tipos-materiales',
    component: () => import('@/views/params/TiposMaterialesView.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'asesor', 'cliente'],
      permission: 'show-tipos-material'
    }
  },
  {
    path: '/estados-materiales',
    component: () => import('@/views/params/EstadosMaterialesView.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin', 'asesor', 'cliente'],
      permission: 'show-estados-material'
    }
  },
  {
    path: '/propietarios',
    component: () => import('@/views/params/PropietariosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/arrendatarios',
    component: () => import('@/views/params/ArrendatariosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/asesores',
    component: () => import('@/views/params/AsesoresView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/inmuebles',
    component: () => import('@/views/InmueblesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/inmuebles/:id/contratos',
    component: () => import('@/views/ContratosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/contratos',
    component: () => import('@/views/ContratosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/contratos/:id/inventario',
    component: () => import('@/views/InventariosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/contratos/:id/siniestros',
    component: () => import('@/views/SiniestrosView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/contratos/:contratoId/siniestros/create',
    name: 'SiniestroCreate',
    component: () => import('@/views/CreateSiniestroView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/contratos/:contratoId/siniestros/:siniestroId',
    name: 'SiniestroDetail',
    component: () => import('@/views/CreateSiniestroView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/proveedores',
    component: () => import('@/views/ProveedoresView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/bitacora',
    component: () => import('@/views/BitacorasView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/roles',
    component: () => import('@/views/params/RolesView.vue'),
    meta: {
      requiresAuth: false,
      roles: ['admin', 'asesor', 'cliente'],
      permission: 'show-roles'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/LoginView.vue'),
    meta: {
      requiresAuth: false,
      roles: ['admin', 'asesor', 'cliente'],
      permission: 'show-login'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

let lastVisitedRoute = null;

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");

  if (to.meta.requiresAuth && !isAuthenticated) {
    lastVisitedRoute = to.fullPath;
    next('/login');
  } else if (isAuthenticated) {
    try {
      const response = await ApiService.post('/get-user', {
        token: localStorage.getItem("token")
      });

      const userData = response.user;
      const userRoles = response.roles || [];
      const userPermissions = response.permissions || [];

      if (
        (!to.meta.roles || to.meta.roles.some(role => userRoles.includes(role))) &&
        (!to.meta.permission || userPermissions.includes(to.meta.permission))
      ) {
        next();
      } else {
        console.error("Acceso denegado. No tienes los permisos necesarios.");
        next('/403');
      }
    } catch (error) {
      console.error("Error al obtener el usuario:", error);
      localStorage.removeItem("token"); 
      next('/login');
    }
  } else {
    next();
  }
});

export default router
