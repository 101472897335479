<template>
  <div id="app">
    <router-view /> <!-- Renderiza el componente de la ruta actual -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Estilos globales de la aplicación */
</style>
