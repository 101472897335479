import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Asegúrate de que la ruta sea correcta
import DataTable from 'datatables.net-vue3';
import DataTableLib from 'datatables.net-bs5';
import 'jquery/dist/jquery.min.js'
import 'jquery';
import './jquery'; 
import {LoadingPlugin} from 'vue-loading-overlay';

DataTable.use(DataTableLib);

const app = createApp(App);
app.use(router);
app.mount('#app');
app.use(DataTable);
app.use(LoadingPlugin, {
    // props
    color: 'red'
}, {
    // slots
})