export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
    },
  },
  actions: {
    login({
      commit,
    }, token) {
      commit('SET_TOKEN', token)
    },
    logout({
      commit,
    }) {
      commit('SET_TOKEN', null)
    },
  },
}
