import axios from 'axios'
import { BASE_API_URL } from '@/config/apiConfig.js';

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  setAuthorizationToken(token) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  async post(endpoint, data) {
    return apiClient.post(endpoint, data)
      .then(response => response.data)
      .catch(error => {
        throw error
      })
  },
  async get(endpoint, data) {
  return apiClient.get(endpoint, data)
    .then(response => response.data)
    .catch(error => {
      throw error
    })
  }
}
