/* export const BASE_API_URL = 'https://siniestros.irisvivienda.com.co/public/api/v1';
export const BASE_FILE_URL = 'https://siniestros.irisvivienda.com.co/public'; */
/*
export const BASE_API_URL = 'http://127.0.0.1:8000/api/v1';
export const BASE_FILE_URL = 'http://127.0.0.1:8000';*/


export const BASE_API_URL = 'https://siniestros.irisvivienda.com.co/public/api/v1';
export const BASE_FILE_URL = 'https://siniestros.irisvivienda.com.co';

